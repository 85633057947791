.title_container {
  width: 100%;
}

@media screen and (min-width: 1756px) {
  .title_container {
    width: 100%;
  }
}

.input {
  @apply text-24 block w-full !bg-transparent border rounded-md focus:ring-2 focus:outline-none focus:ring-golden-600 outline-none border-golden-500 font-helvetica;
}

.error_label {
  @apply mt-2 text-xs text-red-500;
}
